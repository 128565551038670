import React, { lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Modules import with lazy loading
const Login = lazy(() => import("./prelogin-components/login"));
const SignUp = lazy(() => import("./prelogin-components/signup"));
const LandingPage = lazy(() => import("./prelogin-components/landing-page"));
const Home = lazy(() => import("./postlogin-components/home"));
const ContactUs = lazy(() => import("./prelogin-components/contactUs"));
const Blogs = lazy(() => import("./prelogin-components/blogs"));
const OurclientOnUser = lazy(() => import("./prelogin-components/ourClientOnUser"))
const OurTeamOnUser = lazy(() => import("./prelogin-components/ourteamOnUser"))
const RecentCampaignOnUser = lazy(() => import("./prelogin-components/recentCampaignOnUser"))
const AboutUs = lazy(() => import("./prelogin-components/aboutUs"));
const ResetPassword = lazy(() => import("./prelogin-components/resetPassword"));
const AdminHome = lazy(() => import("./postlogin-components/admin/adminHome"));

function App() {
  return (
    <>
      <div className="App">
        <Suspense>
          <Router>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/contactUs" element={<ContactUs />} />
              <Route path="/dashboard/*" element={<Home />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/ourclientOnUser" element={<OurclientOnUser />} />
              <Route path="/ourTeamOnUser" element={<OurTeamOnUser />} />
              <Route path="/recentCampaignOnUser" element={<RecentCampaignOnUser/>} />
              <Route path="/aboutUs" element={<AboutUs />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/adminDashboard/*" element={<AdminHome />} />
            </Routes>
          </Router>
        </Suspense>
      </div>
    </>
  );
}

export default App;
